import { Fragment, useContext, useEffect, useState } from "react";

import classes from "./CheckoutByLink.module.css";
import CartContext from "../../store/cart-context";
import Config from "../../store/config";

const CheckoutByLink = (props) => {
  const [afterDate, setAfterDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pl_id, setplId] = useState(null);
  const [pl, setpl] = useState(null);
  const cartCtx = useContext(CartContext);
  const timerFunction = async (item) => {
    const response = await fetch(Config.url.getReceipt, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();
    if (data.status === "paid") {
      cartCtx.clearCart();
      props.onCancel();
    } else {
      setTimeout(timerFunction.bind(null, item), 500);
    }
  };
  useEffect(() => {
    const fetchPaymentLink = async () => {

      const response = await fetch(Config.url.getPaymentLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ orderedItems: cartCtx.items }),
      });
      const responseBody = await response.json();
      setplId(responseBody.id);
      setpl(responseBody.link);
      const f = {
        after: Date.now(),
        payment_link_id: responseBody.id
      }
      setTimeout(timerFunction.bind(null, f),500);

      setIsLoading(false);
    };
    fetchPaymentLink().catch((error) => {
      console.log(error);
    });
  }, []);

  const qr =
    "http://api.qrserver.com/v1/create-qr-code/?data=" +
    encodeURIComponent(pl) +
    "&size=256x256";

  let content = <p>Loading!</p>;
  if (!isLoading) {
    content = (
      <a href={pl}>
        <img className={classes.qr} src={qr} />
      </a>
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default CheckoutByLink;
