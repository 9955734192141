import { Fragment } from 'react';

import ItemsSummary from './ItemsSummary';
import AvailableItems from './AvailableItems';

const Meals = () => {
  return (
    <Fragment>
      <ItemsSummary />
      <AvailableItems />
    </Fragment>
  );
};

export default Meals;