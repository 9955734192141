import { Fragment } from 'react';

import CartButton from './CartButton';
import classes from './Header.module.css'
import logoImage from '../../assets/coffeeshop.jpg'

const Header = (props) => {
    return (
        <Fragment>
            <header className={classes.header}>
                <h1>Roastery Point of Sale</h1>
                <CartButton onClick={props.onShowCart}/>
            </header>
        <div className={classes['main-image']}>
            <img src={logoImage} alt='Roastery POS Logo'/>
        </div>
        </Fragment>
    );

}

export default Header;