import { useEffect, useState } from "react";

import Card from "../UI/Card";
import MenuItem from "./MenuItem/MenuItem";
import classes from "./AvailableItems.module.css";
import Config from "../../store/config";

const AvailableItems = () => {
  const [meals, setMeals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState();

  useEffect(() => {
    const fetchMeals = async () => {
      
      const response = await fetch(Config.url.getProducts);
      const responseData = (await response.json());

      const loadedMeals = [];

      responseData.map((item) => {
        loadedMeals.push({
          id: item.id,
          name: item.name,
          description: item.description,
          img: item.img,
          price: item.price,
          price_id: item.price_id,
        });
          return null;
      });
      setMeals(loadedMeals);
      setIsLoading(false);
    };

    fetchMeals().catch((error) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
  }, []);

  if (isLoading) {
    return (
      <section className={classes.MealsLoading}>
        <p>Loading...</p>
      </section>
    );
  }

  if (httpError) {
    return (
      <section className={classes.MealsError}>
        <p>{httpError}</p>
      </section>
    );
  }

  const mealsList = meals.map((meal) => (
    <MenuItem
      key={meal.id}
      id={meal.id}
      name={meal.name}
      description={meal.description}
      img={meal.img}
      price={meal.price}
      price_id={meal.price_id}
    />
  ));

  return (
    <section className={classes.meals}>
      <Card>
        <ul>{mealsList}</ul>
      </Card>
    </section>
  );
};

export default AvailableItems;
