import { Fragment, useContext, useEffect, useState } from "react";

import classes from "./CheckoutByCard.module.css";
import CartContext from "../../store/cart-context";
import Config from "../../store/config";

const CheckoutByCard = (props) => {
  const [terminal, setTerminal] = useState(null);
  const [isConnecting, setIsConnecting] = useState(true);
  const [isCollectingPayment, setIsCollectingPayment] = useState(false);
  const [error, setError] = useState(null);
  const cartCtx = useContext(CartContext);
  const fetchConnectionToken = () => {
    return fetch(Config.url.createConnectionToken)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.secret;
      });
  };
  const unexpectedDisconnect = () => {
    setError("Unexpected Disconnect from reader");
  };
  useEffect(() => {
  const t = window.StripeTerminal.create({
    onFetchConnectionToken: fetchConnectionToken,
    onUnexpectedReaderDisconnect: unexpectedDisconnect,
  });
  setTerminal(t);
    var config = { simulated: false };
    t
      .discoverReaders(config)
      .then((discoverResult) => {
        return t.connectReader(discoverResult.discoveredReaders[0]);
      })
      .then((connectResult) => {
        if (connectResult.error) {
          console.log("connection error!!", connectResult.error);
          setError(error);
        } else {
          setError(null);
        }
        setIsConnecting(false);
      });
  }, []);
  useEffect(() => {
    if (isConnecting || error) {
      return;
    }
    fetch(Config.url.createPaymentIntent, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderedItems: cartCtx.items }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        terminal.collectPaymentMethod(data.client_secret).then((result) => {
          if (result.error) {
            setError(result.error);
          } else {
            terminal.processPayment(result.paymentIntent).then((result) => {
              if (result.error) {
                setError(result.error);
              } else {
                fetch(Config.url.capturePaymentIntent, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({"payment_intent_id": result.paymentIntent.id})
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    console.log('server.capture', data);
                })
              }
            });
          }
        });
      });
  }, [isConnecting, error]);

  let content = <p>Connecting to Reader!</p>;
  if (!error) {
    content = <p>Error !!! {error}</p>;
  } else if (isCollectingPayment) {
    content = <p>Collecting payment</p>;
  }

  return <Fragment>{content}</Fragment>;
};

export default CheckoutByCard;
