import React from "react";

const cloudURL = "https://point-of-sale-backend-bu7lxbsdiq-uw.a.run.app";
const localURL = "http://localhost:3001";
const backendURL = process.env.NODE_ENV === "production" ? cloudURL : localURL;

const Config = {
  url: {
    createConnectionToken: backendURL + "/create_connection_token",
    createPaymentIntent: backendURL + "/create_payment_intent",
    capturePaymentIntent: backendURL + "/capture_payment_intent",
    getReceipt: backendURL + "/get_receipt_url",
    getPaymentLink: backendURL + "/get_payment_link",
    getProducts: backendURL + "/get_products",
  },
};

export default Config
